@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

:root {
  --primary-color: #4F46E5;
  --primary-light: #6366f1;
  --secondary-color: #8B5CF6;
  --card-background: #ffffff;
  --border-color: #e5e7eb;
  --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 8px 15px rgba(0, 0, 0, 0.2);
  --text-light: #4b5563;
  --transition: all 0.3s ease;
}

.custom-node {
  padding: 0;
  background: var(--card-background);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  box-shadow: var(--shadow-md);
  position: relative;
  width: 360px;
  min-width: 360px;
  height: auto;
  min-height: 280px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transition: var(--transition);
}

.custom-node:hover {
  box-shadow: var(--shadow-lg);
}

.custom-node-header {
  padding: 12px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #000000;
  text-align: center;
  font-weight: 600;
  font-size: 1.25rem;
}

.node-header-icon {
  font-size: 1.5rem;
}

.node-title {
  margin-left: 10px;
}

.custom-node-content {
  padding: 16px;
  text-align: left;
  overflow: hidden;
  white-space: normal;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.node-description {
  margin-bottom: 12px !important;
  color: var(--text-light);
  font-size: 0.875rem;
  line-height: 1.5;
}

.node-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.linear-progress-bar {
  background-color: #4f46e5 !important;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-top: 1px solid var(--border-color);
  background: var(--card-background);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.left-buttons {
  display: flex;
  gap: 10px;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-button {
  color: var(--text-light);
  background-color: var(--card-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 8px;
  transition: var(--transition);
  margin-bottom: 2px;
}

.icon-button:hover {
  color: white;
  background-color: var(--primary-color);
  transform: translateY(-2px);
}

.gradient-icon {
  background: linear-gradient(45deg, var(--primary-light), var(--primary-color));
  color: white !important;
  border: none;
}

.button-text {
  font-size: 0.6rem;
  color: var(--text-light);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-top: 2px;
}

.node-add-button {
  background: var(--primary-color);
  color: white;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--shadow-md);
  transition: var(--transition);
  position: absolute;
  top: -12px;
  right: -12px;
}

.node-add-button:hover {
  background: var(--primary-light);
  transform: scale(1.1) rotate(90deg);
}

@media (max-width: 768px) {
  .custom-node {
    width: 100%;
    min-width: unset;
  }

  .custom-node-header {
    padding: 12px;
    font-size: 1rem;
  }

  .custom-node-content {
    padding: 12px;
  }

  .button-container {
    padding: 12px;
  }

  .left-buttons {
    gap: 8px;
  }

  .icon-button, .node-add-button {
    width: 32px;
    height: 32px;
    padding: 6px;
  }

  .button-text {
    font-size: 0.5rem;
  }
}