/* GeneratedNode.css */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.generated-node {
  padding: 0;
  background: var(--card-background);
  border: 1px solid var(--border-color);
  border-radius: 16px;
  box-shadow: var(--shadow-md);
  position: relative;
  width: 360px;
  min-width: 360px;
  height: auto;
  min-height: 280px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transition: var(--transition);
}

.generated-node:hover {
  box-shadow: var(--shadow-lg);
}

.generated-node-header {
  padding: 16px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: flex;
  align-items: center;
  gap: 12px;
  background: #E1F5FF;
  color: #000000;
  text-align: center;
  font-weight: bolder;
  font-size: 1.3rem;
}

.node-header-icon {
  font-size: 1.5rem;
}

.node-title {
  margin-left: 10px;
}

.generated-node-content {
  padding: 20px;
  text-align: left;
  overflow: hidden;
  white-space: normal;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.node-description {
  margin-bottom: 16px !important;
  color: var(--text-light);
  font-size: 0.875rem;
  line-height: 1.5;
}

.node-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-top: 1px solid var(--border-color);
  background: var(--background-color);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.left-buttons {
  display: flex;
  gap: 12px;
}

.icon-button {
  color: var(--text-light);
  background-color: var(--card-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 8px;
  transition: var(--transition);
}

.icon-button:hover {
  color: white;
  background-color: var(--primary-color);
  transform: translateY(-2px);
}

.gradient-icon {
  background: linear-gradient(45deg, var(--primary-color), var(--primary-color));
  color: white !important;
  border: none;
}

.node-add-button {
  background: var(--primary-color);
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--shadow-md);
  transition: var(--transition);
  position: absolute;
  top: -15px;
  right: -15px;
}

.node-add-button:hover {
  background: var(--primary-light);
  transform: scale(1.1) rotate(90deg);
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes pulse {
  0% { box-shadow: 0 0 0 0 rgba(79, 70, 229, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(79, 70, 229, 0); }
  100% { box-shadow: 0 0 0 0 rgba(79, 70, 229, 0); }
}

.node-add-button {
  animation: pulse 2s infinite;
}

@media (max-width: 768px) {
  .generated-node {
    width: 100%;
    min-width: unset;
  }

  .generated-node-header {
    padding: 12px;
    font-size: 0.875rem;
  }

  .generated-node-content {
    padding: 16px;
  }

  .button-container {
    padding: 12px;
  }

  .icon-button, .node-add-button {
    width: 32px;
    height: 32px;
  }
}
