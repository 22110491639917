.your-projects {
  padding: 20px;
}

.your-projects-title {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
}

.projects-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.project-card {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: transform 0.2s;
}

.project-card:hover {
  transform: translateY(-5px);
}

.project-card-header {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.project-name {
  font-weight: 500;
  font-size: 1.25rem;
}

.project-date {
  font-size: 0.875rem;
  color: #888888;
}

.project-card-actions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.primary {
  background-color: #42a5f5;
  color: white;
}

.primary:hover {
  background-color: #1e88e5;
}

.secondary {
  color: #42a5f5;
}

.secondary:hover {
  color: #1e88e5;
}

.icon-button-small {
  padding: 5px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-button-small svg {
  font-size: 24px;
}

.save-project {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #f9fafb;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.save-project-button {
  background-color: #3b82f6;
  color: white;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
}

.save-project-button:hover {
  background-color: #1d4ed8;
}

/* Responsive Design */

@media (max-width: 768px) {
  .project-card {
      flex-direction: column;
      align-items: flex-start;
      padding: 15px;
  }

  .project-card-header {
      align-items: flex-start;
  }

  .project-card-actions {
      justify-content: space-between;
      width: 100%;
      margin-top: 10px;
  }

  .icon-button-small {
      width: 32px;
      height: 32px;
  }

  .icon-button-small svg {
      font-size: 20px;
  }

  .your-projects-title {
      font-size: 1.25rem;
  }

  .project-name {
      font-size: 1.125rem;
  }

  .save-project-button {
      padding: 8px;
      font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .your-projects {
      padding: 15px;
  }

  .project-card {
      padding: 10px;
  }

  .project-card-actions {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
  }

  .your-projects-title {
      font-size: 1.125rem;
  }

  .project-name {
      font-size: 1rem;
  }

  .save-project-button {
      padding: 6px;
      font-size: 0.875rem;
  }
}
