@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');


.user-input-node {
  padding: 0;
  background: var(--card-background);
  border: 1px solid var(--border-color);
  border-radius: 16px;
  box-shadow: var(--shadow-md);
  position: relative;
  width: 360px;
  min-width: 360px;
  height: auto;
  min-height: 280px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transition: var(--transition);
}

.user-input-node:hover {
  box-shadow: var(--shadow-lg);
}

.user-input-node-header {
  color: var(--text-color);
  padding: 16px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  text-align: center;
  font-weight: 600;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: var(--secondary-color);
  color: #fff;
}

.node-header-icon {
  font-size: 1.5rem;
  color: #000;
}

.node-title {
  font-weight: 600;
  font-size: 1.25rem;
}

.user-input-node-content {
  padding: 20px;
  text-align: left;
  overflow: hidden;
  white-space: normal;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.node-description {
  margin-bottom: 16px !important;
  color: var(--text-light);
  font-size: 0.875rem;
  line-height: 1.5;
}

.input-field {
  background-color: var(--background-color);
  border-radius: 8px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-top: 1px solid var(--border-color);
  background: var(--background-color);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.icon-button {
  color: var(--text-light);
  background-color: var(--card-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 8px;
  transition: var(--transition);
}

.icon-button:hover {
  color: white;
  background-color: var(--danger-color);
  transform: translateY(-2px);
}

.node-add-button {
  background: var(--primary-color);
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--shadow-md);
  transition: var(--transition);
  position: absolute;
  top: -15px;
  right: -15px;
}

.node-add-button:hover {
  background: var(--secondary-color);
  transform: scale(1.1) rotate(90deg);
}

.handle {
  background: var(--primary-color);
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Add a subtle pulse animation to the add button */
@keyframes pulse {
  0% { box-shadow: 0 0 0 0 rgba(99, 102, 241, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(99, 102, 241, 0); }
  100% { box-shadow: 0 0 0 0 rgba(99, 102, 241, 0); }
}

.node-add-button {
  animation: pulse 2s infinite;
}

/* Media Queries */
@media (max-width: 768px) {
  .user-input-node {
    width: 100%;
    min-width: unset;
  }

  .user-input-node-header {
    padding: 12px;
    font-size: 1rem;
  }

  .user-input-node-content {
    padding: 16px;
  }

  .button-container {
    padding: 12px;
  }

  .icon-button, .node-add-button {
    width: 32px;
    height: 32px;
  }
}
