.read-only-project {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.signup-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.project-visualization {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
