/* src/index.css */
html, body {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

body {
  font-family: Arial, sans-serif;
}

.App {
  display: flex;
  height: 100vh;
}

.workflow {
  display: flex;
  flex: 1;
}

.editor {
  padding: 20px;
  background: #f0f0f0;
}

.loading-message {
  font-size: 18px;
  color: #ff0000;
}

@tailwind base;
@tailwind components;
@tailwind utilities;