.toolbar {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.98);
  padding: 8px 24px;
  border-bottom: 1px solid var(--border-color);
  justify-content: space-between;
  box-shadow: var(--shadow-sm);
  position: sticky;
  top: 0;
  z-index: 50;
  backdrop-filter: blur(8px);
}

.toolbar-buttons {
  display: flex;
  gap: 12px;
}

/* We'll rely more on inline styles for purple circle backgrounds,
   so keep minimal custom CSS here. */

.dropdown-menu {
  display: flex;
  flex-direction: column;
  background-color: var(--card-background);
  border: 1px solid var(--border-color);
  border-radius: 16px;
  padding: 24px;
  margin-top: 12px;
  position: absolute;
  left: 24px;
  top: 72px;
  z-index: 1000;
  width: 50vw;
  max-width: 550px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  animation: slideDown 0.2s ease-out;
}

@keyframes slideDown {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Grid for modules, actions, user input, etc. */
.dropdown-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2-column grid */
  gap: 16px;
  padding: 16px 0;
  width: 100%;
  box-sizing: border-box;
}

.dropdown-item {
  background: var(--card-background);
  border-radius: 12px;
  border: 1px solid var(--border-color);
  transition: var(--transition);
  height: 100%;
  overflow: hidden;
}

.dropdown-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
  border-color: var(--primary-light);
}

.menu-heading {
  color: var(--text-color);
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0 0 16px 0;
  padding: 0;
}

.custom-scenarios-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin: 16px 0;
}

.current-project {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.current-project-name {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--text-color);
  max-width: 300px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-and-credits {
  display: flex;
  align-items: center;
  gap: 16px;
}

.update-project-button {
  /* We rely mostly on inline styling for the circle background & hover state */
  transition: var(--transition) !important;
}

.update-project-button:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .toolbar {
    padding: 8px 16px;
  }

  .dropdown-menu {
    width: 90vw;
    left: 50%;
    transform: translateX(-50%);
    padding: 16px;
  }

  .dropdown-grid {
    grid-template-columns: 1fr;
    gap: 12px;
  }

  .custom-scenarios-grid {
    grid-template-columns: 1fr;
  }

  .current-project-name {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .toolbar {
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
  }

  .toolbar-buttons {
    gap: 8px;
  }

  .profile-and-credits {
    justify-content: center;
    width: 100%;
  }

  .current-project {
    order: -1;
    width: 100%;
    margin-bottom: 8px;
  }

  .dropdown-menu {
    padding: 12px;
    max-height: 70vh;
  }
}

/* Dialog styling */
.MuiDialog-paper {
  border-radius: 16px !important;
  box-shadow: var(--shadow-lg) !important;
}

.MuiDialogTitle-root {
  background: var(--card-background);
  border-bottom: 1px solid var(--border-color);
}

.MuiDialogContent-root {
  padding: 24px !important;
}

.MuiTab-root {
  text-transform: none !important;
  font-weight: 500 !important;
}

/* Divider styling */
.MuiDivider-root {
  margin: 24px 0;
  background-color: #f0f0f0;
}
