:root {
  --primary-color: #4f46e5;
  --primary-light: #6366f1;
  --secondary-color: #4f46e5;
  --background-color: #f8fafc;
  --text-color: #1e293b;
  --text-light: #64748b;
  --border-color: #e2e8f0;
  --card-background: #ffffff;
  --danger-color: #ef4444;
  --success-color: #10b981;
  --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.editor-container {
  margin: 20px auto;
  border-radius: 16px;
  background-color: var(--card-background);
  box-shadow: var(--shadow-lg);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 95vh;
  max-width: 1200px;
  transition: var(--transition);
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}


.header-description {
  padding-left: 16px;
  color: var(--text-color);
  font-weight: 600;
  font-size: 1rem;
  transition: var(--transition);
}

.tabs {
  background-color: var(--card-background);
  border-bottom: 1px solid var(--border-color);
  padding: 0 16px;
  transition: var(--transition);
}

.tabs .MuiTabs-indicator {
  background-color: var(--primary-color);
  height: 3px;
  transition: var(--transition);
}

.tabs .MuiTab-root {
  text-transform: none;
  font-weight: 500;
  min-width: 120px;
  color: var(--text-light);
  transition: var(--transition);
  font-size: 0.875rem;
}

.tabs .MuiTab-root:hover,
.tabs .MuiTab-root.Mui-selected {
  color: var(--primary-color);
  transform: translateY(-2px);
}

.editor-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  background-color: var(--background-color);
  overflow: auto;
  transition: var(--transition);
}

.html-editor {
  flex-grow: 1;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 16px;
  background-color: var(--card-background);
  outline: none;
  margin-top: 16px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: var(--text-color);
  overflow: auto !important;
  max-height: 100% !important;
  max-width: 100% !important;
  transition: var(--transition);
}

.html-editor:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.2);
  transform: translateY(-2px);
}

button {
  text-transform: none;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 0.875rem;
}

button.MuiButton-containedPrimary {
  background-color: var(--primary-color);
  color: white;
  margin-right: 12px;
}

button.MuiButton-containedSecondary {
  background-color: var(--secondary-color);
  color: white;
}

button.MuiButton-outlined {
  border: 1px solid var(--border-color);
  color: var(--text-color);
}

button:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

button.MuiButton-containedPrimary:hover {
  background-color: var(--primary-light);
}

button.MuiButton-containedSecondary:hover {
  background-color: #38bdf8;
}

button.MuiButton-outlined:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.MuiTextField-root {
  background-color: var(--card-background);
  border-radius: 8px;
  transition: var(--transition);
}

.MuiTextField-root .MuiOutlinedInput-root {
  border-radius: 8px;
}

.MuiTextField-root:hover {
  box-shadow: var(--shadow-sm);
  transform: translateY(-2px);
}

.MuiInputBase-root {
  padding: 12px;
}

.MuiTooltip-tooltip {
  font-size: 0.75rem;
  background-color: var(--text-color);
  border-radius: 4px;
  padding: 8px 12px;
  transition: var(--transition);
  animation: tooltipFadeIn 0.3s ease-out;
}

@keyframes tooltipFadeIn {
  from { opacity: 0; transform: translateY(5px); }
  to { opacity: 1; transform: translateY(0); }
}

.minimize-button {
  background-color: var(--background-color);
  border-radius: 50%;
  box-shadow: var(--shadow-sm);
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
  border: 1px solid var(--border-color);
}

.minimize-button:hover {
  background-color: var(--card-background);
  transform: scale(1.05) rotate(90deg);
  box-shadow: var(--shadow-md);
}

.minimize-button .MuiSvgIcon-root {
  color: var(--text-light);
  font-size: 20px;
  transition: var(--transition);
}

.minimize-button:hover .MuiSvgIcon-root {
  color: var(--primary-color);
}



/* Media Queries */
@media (max-width: 768px) {
  .editor-container {
    margin: 10px;
    height: 98vh;
  }

  .header-description {
    padding-left: 12px;
    font-size: 0.875rem;
  }

  .tabs .MuiTab-root {
    min-width: 100px;
    font-size: 0.8125rem;
  }

  .editor-body {
    padding: 20px 24px;
  }

  button {
    padding: 8px 16px;
    font-size: 0.8125rem;
  }
}

@media (max-width: 480px) {
  .editor-container {
    margin: 5px;
  }

  .header-description {
    padding-left: 8px;
    font-size: 0.8125rem;
  }

  .tabs .MuiTab-root {
    min-width: 80px;
    font-size: 0.75rem;
  }

  .editor-body {
    padding: 16px 20px;
  }

  .html-editor {
    font-size: 12px;
  }

  button {
    padding: 6px 12px;
    font-size: 0.75rem;
  }

  .minimize-button {
    width: 28px;
    height: 28px;
  }

  .minimize-button .MuiSvgIcon-root {
    font-size: 18px;
  }
}
