/* Profile menu container */
.profile-menu {
    display: flex;
    align-items: center;
    margin-left: auto; /* Ensure it moves to the far right */
  }
  
  /* Profile menu dropdown */
  .profile-menu-dropdown {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 8px;
    min-width: 200px;
  }
  
  /* Profile menu item */
  .profile-menu-dropdown .MuiMenuItem-root {
    padding: 10px 20px;
    font-weight: bold;
    color: #333;
    transition: background-color 0.3s ease;
  }
  
  /* Profile menu item hover effect */
  .profile-menu-dropdown .MuiMenuItem-root:hover {
    background-color: #f0f0f0;
  }
  