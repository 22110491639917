.popup-container {
    width: 420px;
    max-height: 85vh;
    min-height: 300px; /* Ensure sufficient height */
    padding: 0;
    border-radius: 12px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    background-color: var(--card-background);
    position: absolute;
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    z-index: 999;
}

.popup-container:hover {
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.3);
    transform: translateY(-4px);
}

.popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: var(--primary-color);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    color: white;
}

.popup-title {
    font-weight: 600;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    gap: 8px;
}

.popup-close-button {
    color: white;
    cursor: pointer;
}

.popup-divider {
    margin: 0;
    background-color: var(--border-color);
    height: 1px;
}

.popup-content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1;
    overflow-y: auto;
}

.popup-select {
    background-color: var(--background-color);
    border-radius: 8px;
    border: 1px solid var(--border-color);
    padding: 8px 12px;
    font-size: 1rem;
    color: var(--text-color);
    width: 100%;
}

.popup-textfield, .popup-response-field {
    background-color: var(--background-color);
    border-radius: 8px;
    border: 1px solid var(--border-color);
    padding: 8px 12px;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--text-color);
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    flex-grow: 1;
}

.popup-progress {
    display: block;
    margin: 16px auto;
}

.popup-buttons {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    border-top: 1px solid var(--border-color);
    background-color: var(--background-color);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    gap: 8px;
}

.popup-button {
    background-color: var(--primary-color);
    color: white;
    border-radius: 8px;
    padding: 8px 16px;
    transition: background-color 0.3s, color 0.3s;
    cursor: pointer;
    font-weight: 500;
}

.popup-button:hover {
    background-color: var(--primary-light);
}

.spacer {
    height: 20px;
}

.popup-textfield .MuiInputLabel-root, .popup-response-field .MuiInputLabel-root {
    transform: translate(14px, 14px) scale(1); /* Adjust label position */
}

.popup-textfield .MuiInputLabel-shrink, .popup-response-field .MuiInputLabel-shrink {
    transform: translate(0, -1.5px) scale(0.75); /* Adjust label when focused or filled */
}

.popup-textfield .MuiInputBase-input, .popup-response-field .MuiInputBase-input {
    padding: 3px 0 0 0 !important; /* Adjust padding to avoid overlap with label */
}