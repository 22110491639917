/* Existing styles */

.App {
  display: flex;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.canvas-container {
  flex: 1;
  height: 100%;
  position: relative;
  z-index: 1;
  transition: width 0.3s ease-in-out;
}

.divider {
  width: 5px;
  background-color: #ccc;
  cursor: ew-resize;
  position: absolute;
  top: 0;
  height: 100vh;
  z-index: 100;
}

.editor-pane {
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
  transform: translateX(100%);
  z-index: 50;
}

.editor-pane.show {
  transform: translateX(0);
}

/* New styles for mobile screens */

@media (max-width: 768px) {
  .editor-pane {
    width: 100% !important; /* Editor pane takes full width */
    transform: translateX(100%);
  }

  .editor-pane.show {
    transform: translateX(0);
  }

  .canvas-container {
    display: none; /* Hide the canvas container */
  }

  .divider {
    display: none; /* Hide the divider */
  }
}
